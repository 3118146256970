document.addEventListener("turbolinks:load", function() {
  jQuery(function() {
    return $('#search_dish').autocomplete({
      source: function(request, response) {
        // Fetch data from the server
        $.getJSON($('#search_dish').data('autocomplete-source'), { term: request.term }, function(data) {
          // Filter unique results
          const uniqueData = [...new Set(data)];
          response(uniqueData);
        });
      },
      minLength: 3
    });
  });
});
